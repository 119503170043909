import { SVGProps } from 'react';

export function MailIcon(props: Readonly<SVGProps<SVGSVGElement>>) {
  return (
    <svg
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M27 23.5998L11.7 29.2998C9.2 30.2998 6.3 28.9998 5.4 26.4998L4.5 24.1998C5.5 26.4998 8.2 27.5998 10.6 26.6998L25.9 20.9998C28.4 20.0998 29.7 17.2998 28.8 14.7998L29.8 17.3998C30.8 19.8998 29.5 22.7998 27 23.5998Z"
        fill="#AEDFEF"
      />
      <path
        d="M25.9993 20.8997L10.6993 26.5997C8.29931 27.4997 5.59931 26.3997 4.59931 24.0997C4.59931 23.9997 4.49931 23.8997 4.49931 23.6997L1.79931 16.3997C0.799311 13.8997 2.09931 10.9997 4.59931 10.0997L24.3993 2.69971L28.8993 14.5997C29.7993 17.0997 28.4993 19.8997 25.9993 20.7997V20.8997Z"
        fill="white"
      />
      <path
        d="M26.7993 9.0998L29.8993 17.2998C30.8993 19.7998 29.5993 22.6998 27.0993 23.5998L11.7993 29.2998C9.29931 30.2998 6.39931 28.9998 5.49931 26.4998L1.79931 16.4998C0.799311 13.9998 2.09931 11.0998 4.59931 10.1998L17.7993 5.2998"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 17.4L16 20.6C17.1 20.9 18.2 20.4 18.8 19.5L23.8 10.5"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.7996 7.2999C25.4996 7.2999 26.9996 5.8999 26.9996 4.0999C26.9996 2.2999 25.5996 0.899902 23.7996 0.899902C21.9996 0.899902 20.5996 2.2999 20.5996 4.0999C20.5996 5.8999 21.9996 7.2999 23.7996 7.2999Z"
        fill="#246DF0"
      />
    </svg>
  );
}

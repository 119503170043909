export const getAgeFromDate = (date: string | Date) => {
  const today = new Date();
  const dateObject = new Date(date);
  let age = today.getFullYear() - dateObject.getFullYear();
  const monthDiff = today.getMonth() - dateObject.getMonth();
  const dateDiff = today.getDate() < dateObject.getDate();
  if (monthDiff < 0 || (monthDiff === 0 && dateDiff)) {
    age--;
  }
  return age;
};

'use client';

import { useSuppportModal } from '@/features/support/modal';

import { HeaderButtonChip } from './header-chip';

type HeaderHelpChipProps = {
  className?: string;
};

export function HeaderHelpChip({ className }: Readonly<HeaderHelpChipProps>) {
  const { toggleSupportModal } = useSuppportModal();
  return (
    <HeaderButtonChip
      label="Need help?"
      onClick={toggleSupportModal}
      className={className}
    />
  );
}

import { ProfileAddress } from '../types';

export function stringifyAddress(address: ProfileAddress) {
  return [
    address.line2,
    address.line1,
    address.city,
    address.state,
    address.zipCode,
    address.countryCode,
  ]
    .filter(Boolean)
    .join(', ');
}

'use client';

import { useForm } from 'react-hook-form';

import { useProfileContext } from '@/features/profile/shared/providers';

import {
  AlertDialogThumbnail,
  alertDialog,
} from '@/ui/components/alert-dialog';
import { DialogAction } from '@/ui/components/dialog';

import { SupportCallFormValues } from '../../types';
import { useQueueSupportCall } from './use-queue-support-call';

import { config } from '@/config';

export function useSupportCallForm() {
  const { handleSubmit, ...form } = useForm<SupportCallFormValues>({
    defaultValues: {
      phoneNumber: {
        number: '',
        countryCode: '',
        selectedCountry: 'GB',
      },
      isSupportAvailable: false,
    },
  });
  const queueSupportCall = useQueueSupportCall();
  const { profile } = useProfileContext();
  async function onSubmit(data: SupportCallFormValues) {
    const { phoneNumber, isSupportAvailable } = data;
    queueSupportCall
      .mutateAsync({
        data: {
          firstName: profile?.firstName,
          phone: `+${phoneNumber.countryCode}${phoneNumber.number}`,
          client: config.XCALLY_CLIENT_NAME,
        },
      })
      .then((response) => {
        alertDialog.build({
          size: 'large',
          title: isSupportAvailable
            ? "You'll receive a call soon"
            : "We'll reach out soon",
          description: isSupportAvailable
            ? "Heads up! We'll be calling you in 15-30 minutes. Keep your phone with you and notifications on!"
            : `You'll receive a call from us once we are available. Available ${config.SUPPORT_AVAILABILITY}.`,
          cover: (
            <AlertDialogThumbnail
              alt="Hour glass image"
              width={128}
              src="/assets/images/hourglass.png"
            />
          ),
          renderButtons: ({ confirm }) => (
            <DialogAction onClick={confirm}>Continue</DialogAction>
          ),
        });
      })
      .catch((error) => {
        /** We dont need to show anything here, as this handle by toast error */
      });
  }
  return {
    ...form,
    handleSubmit: handleSubmit(onSubmit),
    isSubmitting: queueSupportCall.isPending,
    isSuccess: queueSupportCall.isSuccess,
  };
}

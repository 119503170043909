import React from 'react';

import { BaseHeader } from '@/ui/components/header';
import { HeaderCloseIconButton } from '@/ui/components/header/components';
import { ProfileBadge } from '@/ui/components/profile-badge';

import { ZendeskConfigScript } from '@/config';

type SupportHeaderProps = {
  onClose?: () => void;
  insideModal?: boolean;
};

export const SupportHeader = ({ onClose, insideModal }: SupportHeaderProps) => {
  return (
    <>
      <ZendeskConfigScript />
      <BaseHeader>
        <HeaderCloseIconButton onClose={onClose} insideModal={insideModal} />
        <ProfileBadge />
      </BaseHeader>
    </>
  );
};

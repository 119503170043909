'use client';

import { ArrowLeftIcon } from '@/ui/svgs/arrow-left-icon';

import { useRouter } from '@/lib/navigation';

import { HeaderIconButton } from './header-icon-button';

export function HeaderBackIconButton({
  onClick,
  children,
}: {
  onClick?: () => void;
  children?: React.ReactNode;
}) {
  const router = useRouter();

  return (
    <HeaderIconButton onClick={onClick ?? router.back}>
      {children || <ArrowLeftIcon />}
    </HeaderIconButton>
  );
}

import { Typography } from '@kamona/components';

import { CardIcon } from '@/ui/components/card';
import { Column } from '@/ui/components/flex';
import { HeartIcon } from '@/ui/svgs/heart-icon';

export function FeedbackWidget() {
  return (
    <>
      <CardIcon>
        <HeartIcon />
      </CardIcon>
      <Column align="start" gap="units-unit16">
        <Typography
          align="left"
          color="content-heading-default"
          variant="body-large"
          weight="semibold"
        >
          Help us improve Moniepoint
        </Typography>
        <Typography align="left" variant="body" color="content-subtext-default">
          Leave a feedback and tell us how we are doing
        </Typography>
      </Column>
    </>
  );
}

export function CallRingingIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        d="M5.31726 1.28657C5.88395 1.40369 6.33524 1.78443 6.61564 2.28746L7.50885 3.88991C7.83786 4.48011 8.11473 4.9768 8.29554 5.40857C8.48735 5.86658 8.60126 6.31824 8.54919 6.8176C8.49711 7.31696 8.29246 7.7354 8.01029 8.14399C7.74428 8.52917 7.37088 8.95804 6.92718 9.46767L5.61417 10.9759C5.37889 11.2461 5.26124 11.3812 5.25049 11.5501C5.23974 11.719 5.33616 11.8633 5.529 12.1518C7.17259 14.6109 9.38773 16.8268 11.8488 18.4718C12.1374 18.6647 12.2816 18.7611 12.4505 18.7503C12.6194 18.7396 12.7546 18.6219 13.0248 18.3866L14.5331 17.0736C15.0427 16.6299 15.4716 16.2565 15.8568 15.9905C16.2653 15.7083 16.6838 15.5036 17.1831 15.4516C17.6825 15.3995 18.1342 15.5134 18.5922 15.7052C19.0239 15.886 19.5206 16.1629 20.1107 16.4918L21.7133 17.3851C22.2163 17.6655 22.5971 18.1168 22.7142 18.6835C22.8325 19.2561 22.658 19.8316 22.2724 20.3047C20.8735 22.021 18.6322 23.1139 16.281 22.6396C14.8358 22.348 13.4098 21.8623 11.6851 20.8732C8.2197 18.8858 5.11263 15.777 3.12755 12.3157C2.13843 10.591 1.65272 9.165 1.36118 7.71974C0.88688 5.36852 1.97971 3.12724 3.69608 1.72833C4.16911 1.34279 4.74466 1.16822 5.31726 1.28657Z"
      />
      <path
        fill="#246DF0"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 2C14.5523 2 15 2.44772 15 3V5.8C15 6.35228 14.5523 6.8 14 6.8C13.4477 6.8 13 6.35228 13 5.8V3C13 2.44772 13.4477 2 14 2ZM19.6573 4.34367C20.0478 4.7342 20.0478 5.36736 19.6573 5.75789L17.6774 7.73779C17.2869 8.12831 16.6537 8.12831 16.2632 7.73779C15.8727 7.34726 15.8727 6.7141 16.2632 6.32357L18.2431 4.34367C18.6336 3.95315 19.2668 3.95315 19.6573 4.34367ZM17.2 10C17.2 9.44772 17.6477 9 18.2 9H21C21.5523 9 22 9.44772 22 10C22 10.5523 21.5523 11 21 11H18.2C17.6477 11 17.2 10.5523 17.2 10Z"
      />
    </svg>
  );
}

'use client';

import { Typography } from '@kamona/components';
import { DialogProps } from '@radix-ui/react-dialog';
import { omit } from 'lodash';
import { Controller } from 'react-hook-form';

import { Card, CardIcon } from '@/ui/components/card';
import {
  DialogAction,
  DialogClose,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/ui/components/dialog';
import PhoneInput from '@/ui/components/phone-input';
import { phoneValidationRules } from '@/ui/components/phone-input/phone-input-data';
import { SharedDialog } from '@/ui/components/shared-dialog';
import { TextField } from '@/ui/components/text-field';
import { CallMissedIcon } from '@/ui/svgs/call-missed-icon';
import { CallRingingIcon } from '@/ui/svgs/call-ringing-icon';

import { useDialogReset } from '@/shared/hooks';
import { CountryCode } from '@/shared/types';

import { countryCollection } from '@/lib/constants';

import { useSupportCallForm } from '../shared/hooks/use-support-call-form';

import { config } from '@/config';

export function CallBackDialog({ open, onOpenChange }: Readonly<DialogProps>) {
  function checkIfSupportIsAvailable() {
    const now = new Date();

    // Convert current time to GMT+1
    const nowInGMTPlus1 = new Date(
      now.toLocaleString('en-US', { timeZone: 'Africa/Lagos' }),
    );

    // Get day, hour, and minutes
    const day = nowInGMTPlus1.getDay(); // Sunday = 0, Monday = 1, ..., Saturday = 6
    const hour = nowInGMTPlus1.getHours();
    const minute = nowInGMTPlus1.getMinutes();

    // Check if current time is between Mon to Fri and between 8am to 5pm
    const isWeekday = day >= 1 && day <= 5; // Mon to Fri
    const isWithinHours =
      hour >= 8 && (hour < 17 || (hour === 17 && minute === 0)); // 8am to 5pm

    return isWeekday && isWithinHours;
  }
  const isSupportAvailable = checkIfSupportIsAvailable();

  const {
    control,
    handleSubmit,
    isSubmitting,
    formState: { errors },
    setValue,
    isSuccess,
    reset,
  } = useSupportCallForm();

  setValue('isSupportAvailable', isSupportAvailable);

  useDialogReset({ isSuccess, open, reset, onOpenChange });

  return (
    <SharedDialog open={open} onOpenChange={onOpenChange}>
      <DialogHeader className="items-center">
        <DialogTitle>We are currently available</DialogTitle>
      </DialogHeader>
      <Card variant="outline">
        <CardIcon>
          {isSupportAvailable ? <CallRingingIcon /> : <CallMissedIcon />}
        </CardIcon>
        <Typography
          variant="body"
          color="content-subtext-default"
          className="flex-grow"
        >
          {isSupportAvailable
            ? 'Leave your phone number for a callback within 15-30min.'
            : 'Leave your phone number for a callback.'}{' '}
          <Typography
            as="span"
            untrimmed
            variant="body"
            weight="medium"
            color="content-heading-default"
          >
            Available {config.SUPPORT_AVAILABILITY}.
          </Typography>
        </Typography>
      </Card>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Your phone number"
          className="mt-5"
          error={errors.phoneNumber?.message}
          renderInput={(props) => (
            <Controller
              name="phoneNumber"
              control={control}
              rules={phoneValidationRules}
              render={({ field }) => (
                <PhoneInput
                  {...omit(field, ['ref'])}
                  aria-required="true"
                  countries={['GB'] as CountryCode[]}
                />
              )}
            />
          )}
        />
        <DialogFooter align="stretch">
          <DialogClose onClick={() => onOpenChange?.(false)}>
            Cancel
          </DialogClose>
          <DialogAction
            onClick={handleSubmit}
            disabled={isSubmitting}
            loading={isSubmitting}
          >
            Submit
          </DialogAction>
        </DialogFooter>
      </form>
    </SharedDialog>
  );
}

import { useHttpQuery } from '@/shared/hooks';

import { FAQs } from '../types';

export const useGetFaqsQuery = useHttpQuery<never, FAQs>;

export function useGetFaqs(options?: Parameters<typeof useGetFaqsQuery>[0]) {
  return useGetFaqsQuery({
    ...options,
    url: `/profile/v1/faqs`,
    queryKey: ['faqs'],
    select: (response) => response,
  });
}

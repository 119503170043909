'use client';

import { Typography } from '@kamona/components';

import { CardIcon } from '@/ui/components/card';
import { Column } from '@/ui/components/flex';
import { PhoneIcon } from '@/ui/svgs/phone-icon';

import { config } from '@/config';

type CallBackWidgetProps = {
  isProfileAvailable: boolean;
};

export function CallBackWidget({
  isProfileAvailable,
}: Readonly<CallBackWidgetProps>) {
  return (
    <>
      <CardIcon>
        <PhoneIcon />
      </CardIcon>
      <Column align="start">
        <Typography
          align="left"
          color="content-heading-default"
          variant="body-large"
          weight="semibold"
          className="mb-units-unit16"
        >
          Speak to us
        </Typography>
        {isProfileAvailable ? (
          <>
            <Typography
              as="p"
              variant="body"
              color="content-subtext-default"
              className="text-left flex items-center  text-nowrap"
            >
              {config.SUPPORT_AVAILABILITY}
              <Typography
                as="a"
                className="ml-units-unit8"
                color="content-heading-default"
                decoration="underline"
                href={`tel:${config.SUPPORT_PHONE}`}
                variant="body"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {config.SUPPORT_PHONE}
              </Typography>
            </Typography>
            <Typography
              variant="body"
              color="content-subtext-default"
              className="flex items-center text-nowrap"
            >
              or
              <Typography
                variant="body"
                color="primary-l7"
                className="cursor-pointer ml-units-unit8"
              >
                request call back
              </Typography>
            </Typography>
          </>
        ) : (
          <>
            <Typography
              variant="body"
              color="content-subtext-default"
              className="mb-units-unit8"
            >
              {config.SUPPORT_AVAILABILITY}
            </Typography>
            <Typography
              variant="body"
              color="content-subtext-default"
              className="flex items-center text-nowrap"
            >
              <Typography
                as="a"
                className="mr-units-unit8"
                color="content-heading-default"
                decoration="underline"
                href={`tel:${config.SUPPORT_PHONE}`}
                variant="body"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {config.SUPPORT_PHONE}
              </Typography>
              or
              <Typography
                variant="body"
                color="primary-l7"
                className="cursor-pointer ml-units-unit8"
              >
                request call back
              </Typography>
            </Typography>
          </>
        )}
      </Column>
    </>
  );
}

import { IconButton, IconButtonProps } from '@kamona/components';

export function HeaderIconButton(props: IconButtonProps) {
  return (
    <IconButton
      rounded
      size="small"
      variant="ghost"
      appearance="plain"
      {...props}
    />
  );
}

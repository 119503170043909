import { Typography } from '@kamona/components';

import { CardIcon } from '@/ui/components/card';
import { Column } from '@/ui/components/flex';
import { MailIcon } from '@/ui/svgs/mail-icon';

import { config } from '@/config';

export function EmailWidget() {
  return (
    <>
      <CardIcon>
        <MailIcon />
      </CardIcon>
      <Column align="start" gap="units-unit16">
        <Typography
          variant="body-large"
          weight="semibold"
          color="content-heading-default"
        >
          Send an email
        </Typography>
        <Typography variant="body" color="content-subtext-default">
          Email us at {config.SUPPORT_EMAIL}
        </Typography>
      </Column>
    </>
  );
}

import { TextArea } from '@kamona/components';
import { useRef } from 'react';

import {
  AlertDialogThumbnail,
  alertDialog,
} from '@/ui/components/alert-dialog';
import { DialogAction, DialogClose } from '@/ui/components/dialog';
import { Row } from '@/ui/components/flex';
import { RadioGroup, RadioGroupItem } from '@/ui/components/radio-group';
import { Text } from '@/ui/components/text';

import { FeedbackType } from '../../types';
import { useAddFeedback } from './use-add-feedback';
import { useGetFeedbackTypes } from './use-get-feedback-types';

export function useFeedbackWidget() {
  const { data: feedbackTypes } = useGetFeedbackTypes();
  const addFeedback = useAddFeedback();

  // Refs to hold the selected feedback type and feedback description
  const selectedFeedbackTypeRef = useRef<string>('');
  const feedbackMessageRef = useRef<string>('');

  function onClickSubmitFeedback() {
    addFeedback.mutate(
      {
        data: {
          type: selectedFeedbackTypeRef.current,
          message: feedbackMessageRef.current,
        },
      },
      {
        onSuccess: () => {
          alertDialog.build({
            title: 'Feedback received',
            description:
              'Your feedback matters to us! Thanks for helping us improve your experience.',
            cover: (
              <AlertDialogThumbnail
                alt=""
                width={128}
                src="/assets/images/open-letter.png"
              />
            ),
            renderButtons: ({ confirm }) => (
              <DialogAction onClick={confirm}>Done</DialogAction>
            ),
          });
        },
        onError: (error) => {
          alertDialog.danger({
            title: 'Error',
            description: 'An error occurred while submitting your feedback',
            renderButtons: ({ close }) => (
              <DialogAction onClick={close}>Close</DialogAction>
            ),
          });
        },
      },
    );
  }

  return function requestFeedback() {
    alertDialog.build({
      title: 'Leave a feedback',
      headerProps: { align: 'start' },
      description: 'Your feedback helps create a better experience',
      content: (
        <>
          <RadioGroup
            onValueChange={(value) => {
              selectedFeedbackTypeRef.current = value;
            }}
          >
            {feedbackTypes?.map((option: FeedbackType) => (
              <Row key={option.type} align="center" gap="units-unit8">
                <RadioGroupItem value={option.type} id={option.type} />
                <Text
                  as="label"
                  htmlFor={option.type}
                  variant="body-default"
                  color="content-heading-default"
                >
                  {option.description}
                </Text>
              </Row>
            ))}
          </RadioGroup>
          <TextArea
            onChange={(value: string) => {
              feedbackMessageRef.current = value;
            }}
            label="Describe your feedback"
          />
        </>
      ),
      renderButtons: ({ close, confirm }) => (
        <>
          <DialogClose onClick={close}>Cancel</DialogClose>
          <DialogAction
            onClick={() => {
              confirm();
              onClickSubmitFeedback();
            }}
          >
            Submit feedback
          </DialogAction>
        </>
      ),
    });
  };
}

import { Button, ButtonProps } from '@kamona/components';

import { Link } from '@/lib/navigation';

export function HeaderButton(props: Readonly<ButtonProps>) {
  return (
    <Button size="small" variant="outline" appearance="plain" {...props} />
  );
}

export function HeaderLinkButton(
  props: Omit<ButtonProps, 'as'> & { href: string },
) {
  return (
    <HeaderButton
      // @ts-ignore
      as={Link}
      {...props}
    />
  );
}

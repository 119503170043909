import { Chip, ChipProps } from '@kamona/components';

import { Link } from '@/lib/navigation';
import { cn } from '@/lib/utils';

export function HeaderChip({ className, ...props }: Readonly<ChipProps>) {
  return (
    <Chip
      type="action"
      size="medium"
      variant="faded"
      className={{ root: cn('rounded-full', className) }}
      appearance="primary"
      {...props}
    />
  );
}

export function HeaderLinkChip({
  href,
  ...props
}: Omit<ChipProps, 'as'> & { href: string }) {
  return (
    <Link href={href} className="flex items-center">
      <HeaderChip {...props} />
    </Link>
  );
}

export function HeaderButtonChip({
  ...props
}: ChipProps & { onClick: () => void; className?: string }) {
  return (
    <div className={props.className ?? 'flex items-center'}>
      <HeaderChip {...props} />
    </div>
  );
}

import parsePhoneNumber from 'libphonenumber-js';

import { PhoneInfoValue } from '@/features/profile/types';

import { CountryCode } from '@/shared/types';

export const countryOptionsForPhoneInput: CountryCode[] = ['GB'];

export const phoneValidationRules = {
  validate: {
    countryCodeRequired: ({ countryCode }: PhoneInfoValue) =>
      !!countryCode || 'Country code is required',
    phoneNumberRequired: ({ number }: PhoneInfoValue) =>
      !!number || 'Phone number is required',
    validPhoneNumber: ({
      number,
      countryCode,
      selectedCountry,
    }: PhoneInfoValue) => {
      const phoneNumber = parsePhoneNumber(
        `${countryCode}${number}`,
        selectedCountry,
      );

      if (!phoneNumber?.isValid()) {
        return 'Please enter a valid phone number';
      }
    },
  },
};

import { SVGProps } from 'react';

export const LogoutIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.16602 2.5L8.61385 2.69487C6.46491 3.45333 5.39042 3.83257 4.77822 4.69785C4.16602 5.56313 4.16602 6.70258 4.16602 8.9815V11.0185C4.16602 13.2974 4.16602 14.4368 4.77822 15.3022C5.39042 16.1674 6.46491 16.5467 8.61385 17.3052L9.16602 17.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M17.4993 9.99996H9.16602M17.4993 9.99996C17.4993 9.41646 15.8374 8.32623 15.416 7.91663M17.4993 9.99996C17.4993 10.5835 15.8374 11.6737 15.416 12.0833"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

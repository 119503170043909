'use client';

import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { createPortal } from 'react-dom';

import {
  SupportHeader,
  SupportPageComponent,
} from '@/features/support/shared/components';

import { Column } from '@/ui/components/flex';

const SupportModalContext = createContext({
  toggleSupportModal: () => {},
  isSupportModalOpen: false,
});

export const useSuppportModal = () => {
  return useContext(SupportModalContext);
};

const SupportModalProvider = ({ children }: { children: ReactNode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalRoot, setModalRoot] = useState<HTMLElement | null>(null);

  // Function to toggle modal visibility
  const toggleSupportModal = (): void => setIsOpen((prevState) => !prevState);

  // Dynamically create modal-root if it doesn't exist
  useEffect(() => {
    let modalRoot = document.getElementById('modal-root');
    if (!modalRoot) {
      modalRoot = document.createElement('div');
      modalRoot.id = 'modal-root';
      document.body.appendChild(modalRoot);
    }
    setModalRoot(modalRoot);
    return () => {
      if (modalRoot && modalRoot.childNodes.length === 0) {
        modalRoot.remove();
      }
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  const modalContent = isOpen ? (
    <Column className="fixed inset-0 bg-white z-[49] overflow-auto">
      <SupportHeader onClose={toggleSupportModal} insideModal={true} />
      <SupportPageComponent />
    </Column>
  ) : null;

  const value = useMemo(
    () => ({
      toggleSupportModal,
      isSupportModalOpen: isOpen,
    }),
    [isOpen],
  );

  return (
    <SupportModalContext.Provider value={value}>
      {children}
      {modalRoot && createPortal(modalContent, modalRoot)}
    </SupportModalContext.Provider>
  );
};

export default SupportModalProvider;

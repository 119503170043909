import { SVGProps } from 'react';

export function HeartIcon(props: Readonly<SVGProps<SVGSVGElement>>) {
  return (
    <svg
      width="34"
      height="32"
      viewBox="0 0 34 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M32.4259 11.6483C32.4259 16.0369 30.2316 21.3855 24.9002 26.5626C23.0145 28.4312 21.6088 29.6997 19.3974 30.0083C17.2031 30.3169 15.4888 29.374 13.1231 28.1226C6.54021 24.5226 3.0945 19.9626 1.99735 15.7283C1.60307 14.1855 1.48307 12.6769 1.63735 11.2883C1.91164 8.85403 2.95735 6.7626 4.6545 5.37403C8.58021 2.23688 12.5059 2.71688 15.0088 4.1226C15.4888 4.27688 15.6431 4.58546 15.9516 4.58546C16.1059 4.43117 16.4316 4.27688 16.7402 3.81403C18.7802 1.77403 22.5345 0.196884 27.0945 2.23688C29.0659 3.12831 30.6774 4.87688 31.5859 7.15688C32.1174 8.49403 32.4259 10.0197 32.4259 11.6483Z"
        fill="#AEDFEF"
      />
      <path
        d="M31.5853 7.15688C31.2081 11.2712 28.9453 16.0883 24.1281 20.7683C22.2424 22.654 20.8367 23.9055 18.6424 24.2312C16.4481 24.5397 14.7167 23.5969 12.3681 22.3455C6.45386 19.1055 3.07672 15.1112 1.63672 11.2883C1.911 8.85403 2.95672 6.7626 4.65386 5.37403C8.57958 2.23688 12.5053 2.71688 15.0081 4.1226C15.4881 4.27688 15.6424 4.58546 15.951 4.58546C16.1053 4.43117 16.431 4.27688 16.7396 3.81403C18.7796 1.77403 22.5339 0.196884 27.0939 2.23688C29.0653 3.12831 30.6767 4.87688 31.5853 7.15688Z"
        fill="white"
      />
      <path
        d="M4.65493 5.39136C8.58065 2.25422 12.5064 2.71708 15.0092 4.13994C15.4721 4.29422 15.6435 4.60279 15.9521 4.60279C16.1064 4.44851 16.4149 4.29422 16.7406 3.81422C18.7806 1.77422 22.5521 0.214221 27.0949 2.23708C30.2321 3.64279 32.4264 7.25994 32.4264 11.6485C32.4264 16.0371 30.2321 21.3685 24.9006 26.5456C23.0149 28.4314 21.6092 29.6828 19.4149 29.9914C17.2206 30.2999 15.4892 29.3571 13.1406 28.1056C6.55779 24.5056 3.09493 19.9456 1.99779 15.7114C0.900648 11.4771 1.99779 7.55136 4.67208 5.35708L4.65493 5.39136Z"
        stroke="#010205"
        strokeWidth="2"
      />
    </svg>
  );
}

import { DialogProps } from '@radix-ui/react-dialog';
import React from 'react';

import { BottomSheet, BottomSheetContent } from '@/ui/components/bottom-sheet';
import { Dialog, DialogBody, DialogContent } from '@/ui/components/dialog';

import { useMediaQuery } from '@/shared/hooks';

interface SharedDialogProps extends DialogProps {
  children: React.ReactNode;
}

export function SharedDialog({
  open,
  onOpenChange,
  children,
}: Readonly<SharedDialogProps>) {
  const isMd = useMediaQuery('md');

  if (isMd) {
    return (
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent size="large">
          <DialogBody>{children}</DialogBody>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <BottomSheet open={open} onOpenChange={onOpenChange}>
      <BottomSheetContent>{children}</BottomSheetContent>
    </BottomSheet>
  );
}

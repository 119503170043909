'use client';

import { CallBackWidget } from '@/features/support/call-back/shared/components';
import { EmailWidget } from '@/features/support/email/shared/components';
import { FeedbackWidget } from '@/features/support/feedback/shared/components';
import { LiveChatWidget } from '@/features/support/live-chat/shared/components';

import { Card, CardProps } from '@/ui/components/card';
import { useDialog } from '@/ui/components/dialog';

import { checkUserLoggedIn } from '@/shared/utils';

import { cn } from '@/lib/utils';

import { CallBackDialog } from '../../call-back/components/call-back-dialog';
import { FeedbackDialog } from '../../feedback/components/feedback-dialog';
import { openWidget } from '../../utils/zendesk';

import { config } from '@/config';

export function SupportWidgets() {
  const isUserLoggedIn = checkUserLoggedIn();
  const { open, onOpenChange, onOpen } = useDialog();
  const {
    open: feedbackDialogOpen,
    onOpenChange: onFeedbackDialogOpenChange,
    onOpen: onFeedbackDialogOpen,
  } = useDialog();

  const cardProps = {
    align: 'start',
    layout: 'vertical',
    mode: 'action',
    variant: 'outline',
    radius: 'large',
  } satisfies CardProps;

  const handleLiveChatClick = () => {
    openWidget();
  };

  return (
    <>
      <div
        className={cn(
          `grid gap-5 md:grid-cols-2 sm:grid-cols-1 `,
          isUserLoggedIn ? 'lg:grid-cols-2' : 'lg:grid-cols-3',
        )}
      >
        <Card
          {...cardProps}
          className="px-5 py-units-unit32  gap-units-unit20 justify-start"
          onClick={handleLiveChatClick}
        >
          <LiveChatWidget />
        </Card>
        <Card
          {...cardProps}
          onClick={onOpen}
          className="px-5 py-units-unit32  gap-units-unit20 justify-start"
        >
          <CallBackWidget isProfileAvailable={isUserLoggedIn} />
        </Card>
        <Card
          as="a"
          {...cardProps}
          className="px-5 py-units-unit32  gap-units-unit20 justify-start"
          href={`mailto:${config.SUPPORT_EMAIL}`}
        >
          <EmailWidget />
        </Card>
        {isUserLoggedIn && (
          <Card
            {...cardProps}
            onClick={onFeedbackDialogOpen}
            className="px-5 py-units-unit32 gap-units-unit20 justify-start"
          >
            <FeedbackWidget />
          </Card>
        )}
      </div>
      <CallBackDialog {...{ open, onOpenChange }} />
      {isUserLoggedIn && (
        <FeedbackDialog
          {...{
            open: feedbackDialogOpen,
            onOpenChange: onFeedbackDialogOpenChange,
          }}
        />
      )}
    </>
  );
}

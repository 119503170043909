import { useHttpMutation } from '@/shared/hooks';

import { xcallyAPI } from '@/lib/api';

import { SupportCallOptions, SupportCallResponse } from '../../types';

import { config } from '@/config';

export function useQueueSupportCall(
  options?: Parameters<
    typeof useHttpMutation<SupportCallOptions, SupportCallResponse>
  >[0],
) {
  const { ...mutationClientOptions } = options ?? {};

  return useHttpMutation({
    api: xcallyAPI,
    url: '/x-cally/queue-contact-for-call',
    method: 'POST',
    headers: {
      apikey: config.XCALLY_API_KEY,
    },
    isAuthorized: true,
    ...mutationClientOptions,
  });
}

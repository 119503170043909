import { useForm } from 'react-hook-form';

import {
  AlertDialogThumbnail,
  alertDialog,
} from '@/ui/components/alert-dialog';
import { DialogAction } from '@/ui/components/dialog';

import { AddFeedbackRequestPayload } from '../../types';
import { useAddFeedback } from './use-add-feedback';

export function useFeedbackForm() {
  const { handleSubmit, ...form } = useForm({
    defaultValues: {
      type: '',
      message: '',
    },
  });
  const addFeedback = useAddFeedback();

  async function onSubmit(data: AddFeedbackRequestPayload) {
    addFeedback
      .mutateAsync({
        data: {
          type: data.type,
          message: data.message,
        },
      })
      .then(() => {
        alertDialog.build({
          title: 'Feedback received',
          description:
            'Your feedback matters to us! Thanks for helping us improve your experience.',
          cover: (
            <AlertDialogThumbnail
              alt=""
              width={128}
              src="/assets/images/open-letter.png"
            />
          ),
          renderButtons: ({ confirm }) => (
            <DialogAction onClick={confirm}>Done</DialogAction>
          ),
        });
      })
      .catch(() => {
        /** Dont need anything here as we have Toast setup for APis error */
      });
  }

  return {
    ...form,
    handleSubmit: handleSubmit(onSubmit),
    isSubmitting: addFeedback.isPending,
    isSuccess: addFeedback.isSuccess,
  };
}

import { SVGProps } from 'react';

export const HelpCircleIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.99935 18.3333C14.6017 18.3333 18.3327 14.6023 18.3327 9.99996C18.3327 5.39759 14.6017 1.66663 9.99935 1.66663C5.39698 1.66663 1.66602 5.39759 1.66602 9.99996C1.66602 14.6023 5.39698 18.3333 9.99935 18.3333Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M8.33398 7.50004C8.33398 6.57957 9.08015 5.83337 10.0007 5.83337C10.9212 5.83337 11.6673 6.57957 11.6673 7.50004C11.6673 7.83183 11.5704 8.14098 11.4032 8.40071C10.9052 9.17479 10.0007 9.91287 10.0007 10.8334V11.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M9.99414 14.1666H10.0031"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

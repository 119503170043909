import { SVGProps } from 'react';

export function PhoneIcon(props: Readonly<SVGProps<SVGSVGElement>>) {
  return (
    <svg
      width="26"
      height="33"
      viewBox="0 0 26 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24.2312 25.6819L23.0676 24.4304L20.5026 21.7738C20.0519 21.3666 19.4128 21.2997 18.8829 21.5245L17.1076 22.5489C16.5803 22.7833 16.0083 22.8124 15.5505 22.4174C15.25 22.1459 14.7922 21.751 14.3269 21.0578C13.6838 20.319 12.9565 19.1888 12.127 17.7546C10.5978 15.0999 10.0097 13.4458 9.89384 12.4726C9.77459 11.873 10.004 11.3768 10.5492 11.0547L12.3245 10.0303C12.8794 9.70559 13.1267 9.12169 12.9198 8.50419L11.4268 3.24128C11.3426 2.84973 11.0421 2.57825 10.6335 2.36682C10.6335 2.36682 10.5433 2.33923 10.4556 2.32129C10.1096 2.22766 9.82362 2.24218 9.49211 2.43453L4.0478 4.68014C3.60814 4.93253 3.30908 5.32325 3.19751 5.7569C2.71886 7.87288 2.03387 13.5848 5.95447 20.2574C8.89277 25.3505 12.7143 27.8672 15.1971 29.0965C15.9313 29.4795 16.6356 29.6738 17.1389 29.891C17.5751 30.0122 23.9479 27.4453 23.9479 27.4453C24.953 27.0621 24.7175 26.1831 24.2427 25.6478L24.2312 25.6819Z"
        fill="#AEDFEF"
      />
      <path
        d="M22.2209 23.5496L20.515 21.7808C20.0642 21.3736 19.3733 21.1136 18.7174 21.486L16.942 22.5104C16.4501 22.6837 15.8393 22.5678 15.5628 22.4245C13.9965 21.6018 9.37636 19.7754 6.56975 9.53277C6.41187 8.94356 6.7254 8.25909 7.2732 7.94666L9.26227 6.79252C9.81714 6.46785 10.1126 5.71576 9.9031 5.08861L9.22758 2.56754L4.04338 4.70204C3.30946 5.05398 3.06336 5.5651 2.9518 5.99875C2.47315 8.11473 1.78816 13.8266 5.70875 20.4993C8.49264 25.2093 11.4219 27.0229 13.4672 28.2038C14.0061 28.5149 14.9862 28.8113 15.9493 28.3876L21.8333 25.8896C23.0045 25.4309 22.5717 23.9318 22.2112 23.5522L22.2209 23.5496Z"
        fill="white"
      />
      <path
        d="M23.6206 24.9988L23.0685 24.4306L22.2094 23.5426L20.5035 21.7739C20.0527 21.3667 19.3619 21.1066 18.7059 21.4791L16.9306 22.5035C15.872 22.8803 14.8361 21.7188 14.3277 21.0579C13.7277 20.2869 12.9573 19.1889 12.1278 17.7547C10.5986 15.1 10.0105 13.4459 9.89466 12.4727C9.77542 11.8732 10.0048 11.3769 10.55 11.0548L12.3254 10.0304C12.8802 9.70572 13.1275 9.12182 12.9207 8.50432L11.4277 3.24141C11.3434 2.84987 11.0429 2.57839 10.6343 2.36695C10.6343 2.36695 10.5441 2.33936 10.4565 2.32143C10.1104 2.2278 9.82444 2.24231 9.49293 2.43467L4.04862 4.68028C3.31471 5.03222 3.0686 5.54333 2.95704 5.97698C2.47839 8.09297 1.7934 13.8049 5.714 20.4775C8.49788 25.1875 13.0174 28.5317 17.1327 29.9033C17.5689 30.0246 23.9417 27.4577 23.9417 27.4577C24.9093 27.0121 24.5191 26.0192 24.2365 25.6601L23.6206 24.9988Z"
        stroke="#010205"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </svg>
  );
}

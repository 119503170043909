export function MessageIcon(props: Readonly<React.SVGProps<SVGSVGElement>>) {
  return (
    <svg
      width="32"
      height="34"
      viewBox="0 0 32 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M27.8012 24.1L7.30117 32L6.20117 29L26.7012 21.1C29.3012 20.1 30.5012 17.3 29.5012 14.6L26.6012 7C26.6012 7.1 26.7012 7.2 26.7012 7.3L30.6012 17.6C31.6012 20.3 30.4012 23.1 27.8012 24.1Z"
        fill="#AEDFEF"
      />
      <path
        d="M26.7012 21.1L6.20116 29L1.50116 16.8C0.501161 14.2 1.90116 11.4 4.40116 10.4L20.2012 4.39999C22.7012 3.39999 25.4012 4.59999 26.5012 6.89999L29.4012 14.6C30.4012 17.3 29.2012 20.1 26.6012 21.1H26.7012Z"
        fill="white"
      />
      <path
        d="M25.5005 7.30007C27.2005 6.80007 28.2005 4.90007 27.7005 3.20007C27.2005 1.50007 25.3005 0.500069 23.6005 1.00007C21.9005 1.50007 20.9005 3.40007 21.4005 5.10007C21.9005 6.80007 23.8005 7.80007 25.5005 7.30007Z"
        fill="#246DF0"
      />
      <path
        d="M18.3012 5.2002L4.40116 10.5002C1.90116 11.5002 0.50116 14.2002 1.50116 16.9002L6.20116 29.1002L7.30116 32.1002L27.8012 24.2002C30.4012 23.2002 31.6012 20.4002 30.6012 17.7002L27.6012 9.7002"
        stroke="#010205"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0012 19.9004C11.9012 19.9004 11.9012 16.9004 10.0012 16.9004C8.10117 16.9004 8.10117 19.9004 10.0012 19.9004Z"
        fill="#010205"
      />
      <path
        d="M15.5012 17.8003C17.4012 17.8003 17.4012 14.8003 15.5012 14.8003C13.6012 14.8003 13.6012 17.8003 15.5012 17.8003Z"
        fill="#010205"
      />
      <path
        d="M20.9016 15.8003C22.8016 15.8003 22.8016 12.8003 20.9016 12.8003C19.0016 12.8003 19.0016 15.8003 20.9016 15.8003Z"
        fill="#010205"
      />
    </svg>
  );
}

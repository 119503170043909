import { Typography } from '@kamona/components';

import { CardIcon } from '@/ui/components/card';
import { Column } from '@/ui/components/flex';
import { MessageIcon } from '@/ui/svgs/message-icon';

export function LiveChatWidget() {
  return (
    <>
      <CardIcon>
        <MessageIcon />
      </CardIcon>
      <Column align="start" gap="units-unit16">
        <Typography
          variant="body-large"
          weight="semibold"
          color="content-heading-default"
        >
          Start a live chat
        </Typography>
        <Typography variant="body" color="content-subtext-default">
          Available 24/7 every day of the week
        </Typography>
      </Column>
    </>
  );
}
